import React, {useState, useEffect} from 'react'

import {Drawer, Dropdown, IconButton, Panel} from 'rsuite'
import {BlockProps} from '../atoms/blockList'
import {PlaceholderInput} from '../atoms/placeholderInput'
import {TypographicTextArea} from '../atoms/typographicTextArea'
import {ImageBlockValue} from './types'
import {useTranslation} from 'react-i18next'
import {ContentEditor, RefSelectPanel, useRecordHook} from '@dudagroup/editor'
import {ImageRecord} from '../interfaces/interfaces'
import {DEFAULT_LANGUAGE, MODEL_MEDIA} from '../../../config'
import {I18nWrapper} from '../atoms/i18nWrapper'

import Pencil from '@rsuite/icons/legacy/Pencil'
import Image from '@rsuite/icons/legacy/Image'
import Wrench from '@rsuite/icons/legacy/Wrench'

// TODO: Handle disabled prop
export function ImageBlock({
  value,
  onChange,
  configs,
  autofocus,
  languageLaneL,
  languageLaneR,
}: BlockProps<ImageBlockValue>) {
  const {image, caption} = value
  const [isChooseModalOpen, setChooseModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const imageRecord = useRecordHook<ImageRecord>(image || undefined)

  const {t} = useTranslation()

  useEffect(() => {
    if (autofocus && !value.image) {
      setChooseModalOpen(true)
    }
  }, [autofocus, value.image])

  let imageComponent = null

  if (imageRecord?.content?.media?.[DEFAULT_LANGUAGE]?.media?.image) {
    imageComponent = (
      <Panel
        style={{
          padding: 0,
          position: 'relative',
          height: '100%',
          backgroundSize: `${
            imageRecord.content.media[DEFAULT_LANGUAGE].media.image.height > 300
              ? 'contain'
              : 'auto'
          }`,
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${
            imageRecord.content.media[DEFAULT_LANGUAGE].media.url ??
            'https://via.placeholder.com/240x240'
          })`,
        }}>
        <Dropdown
          renderToggle={(props, ref) => (
            <IconButton {...props} ref={ref} appearance="subtle" icon={<Wrench />} circle />
          )}>
          <Dropdown.Item onClick={() => setChooseModalOpen(true)}>
            <Image /> {t('blocks.image.overview.chooseImage')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setEditModalOpen(true)}>
            <Pencil /> {t('blocks.image.overview.editImage')}
          </Dropdown.Item>
          {/* TODO: Meta sync for metadata image */}
        </Dropdown>
      </Panel>
    )
  }
  return (
    <>
      <Panel
        bodyFill={true}
        style={{
          height: 300,
          overflow: 'hidden',
          marginBottom: 10,
        }}>
        <PlaceholderInput onAddClick={() => setChooseModalOpen(true)}>
          {imageComponent}
        </PlaceholderInput>
      </Panel>
      <I18nWrapper
        lane1={
          <TypographicTextArea
            variant="subtitle2"
            align="center"
            placeholder={t('blocks.image.overview.caption')}
            value={caption[languageLaneL]}
            onChange={(e) => {
              onChange(e.target.value, ['caption', languageLaneL])
            }}
          />
        }
        lane2={
          <TypographicTextArea
            variant="subtitle2"
            align="center"
            placeholder={t('blocks.image.overview.caption')}
            value={caption[languageLaneR]}
            onChange={(e) => {
              onChange(e.target.value, ['caption', languageLaneR])
            }}
          />
        }></I18nWrapper>

      <Drawer
        open={isChooseModalOpen}
        placement={'bottom'}
        size="full"
        onClose={() => setChooseModalOpen(false)}>
        <Drawer.Body>
          <RefSelectPanel
            refConfig={{
              [MODEL_MEDIA]: {
                scope: 'local',
              },
            }}
            configs={configs}
            onClose={() => setChooseModalOpen(false)}
            onSelectRef={(ref) => {
              setChooseModalOpen(false)
              onChange(ref, ['image'])
            }}
          />
        </Drawer.Body>
      </Drawer>
      {image && (
        <Drawer
          open={isEditModalOpen}
          placement={'bottom'}
          size="full"
          backdrop="static"
          onClose={() => setEditModalOpen(false)}>
          <ContentEditor
            onBack={() => setEditModalOpen(false)}
            id={image.recordId}
            type={MODEL_MEDIA}
            configs={configs}></ContentEditor>
        </Drawer>
      )}
    </>
  )
}
