import React from 'react'
import {BlockProps} from '../atoms/blockList'
import {ListInput, FieldProps} from '../atoms/listInput2'
import {Configs, isFunctionalUpdate} from '@dudagroup/editor'
import {LinkListBlockValue, LinkListBlockValueItem, ListTypeBlockValue} from './types'
import {Input} from 'rsuite'
import {getEmptyI18nValue} from '../utility'
import update from 'immutability-helper'
import {LinkComponent} from '../atoms/LinkComponent'
import {SelectPicker} from 'rsuite'

export function LinkListBlock({
  value,
  onChange,
  configs,
  disabled,
  languageLaneL,
  languageLaneR,
}: BlockProps<LinkListBlockValue>) {
  return (
    <div>
      <div style={{marginTop: '20px', marginBottom: '50px', width: '50%'}}>
        <SelectPicker
          value={value.listType}
          defaultValue={ListTypeBlockValue.list}
          cleanable={false}
          onChange={(val) => {
            onChange(val, ['listType'])
          }}
          searchable={false}
          data={[
            {
              label: 'Einfach',
              value: ListTypeBlockValue.list,
            },
            {
              label: 'Unsortiert',
              value: ListTypeBlockValue.unorderedList,
            },
            {
              label: 'Nummeriert',
              value: ListTypeBlockValue.orderedList,
            },
          ]}
          style={{width: '50%', paddingTop: '10'}}
        />
      </div>

      <ListInput
        value={value.linkList}
        onChange={(items) => {
          const val = isFunctionalUpdate(items) ? items(value.linkList) : items
          onChange(val, ['linkList'])
        }}
        defaultValue={
          {
            linkText: getEmptyI18nValue(configs.apiConfig.languages, ''),
            link: {
              external: {
                href: getEmptyI18nValue(configs.apiConfig.languages, 'https://'),
                target: '_blank',
              },
            },
          } as any
        }
        disabled={disabled}>
        {(props) => (
          <LinkListElement
            {...props}
            configs={configs}
            languageLaneL={languageLaneL}
            languageLaneR={languageLaneR}
          />
        )}
      </ListInput>
    </div>
  )
}

export function LinkListElement({
  value,
  configs,
  onChange,
  languageLaneL,
  languageLaneR,
}: FieldProps<LinkListBlockValueItem> & {
  configs: Configs
  languageLaneL: string
  languageLaneR: string
}) {
  return (
    <>
      <div style={{flexGrow: 1}}>
        <Input
          placeholder="Link Text"
          value={value.linkText[languageLaneL]}
          onChange={(val) => {
            onChange(
              update(value, {
                linkText: {[languageLaneL]: {$set: val}},
              })
            )
          }}
        />
        <br></br>
        <LinkComponent
          value={value.link}
          configs={configs}
          refConfig={{
            ['media']: {
              scope: 'local',
            },
            ['page']: {
              scope: 'local',
            },
            ['event']: {
              scope: 'local',
            },
            ['news']: {
              scope: 'local',
            },
            ['multimedia']: {
              scope: 'local',
            },
            ['project']: {
              scope: 'local',
            },
          }}
          languageLaneL={languageLaneL}
          languageLaneR={languageLaneR}
          onChange={(val) => {
            onChange(
              update(value, {
                link: {$set: val as any},
              })
            )
          }}></LinkComponent>
      </div>
    </>
  )
}
